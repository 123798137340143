html,body,h1,h2,h3,h4{
    font-family:"Lato", sans-serif
  }

  a {
    text-decoration: none;
  }

  #directors {
    display: flex;
    justify-content: center;
    gap: 20px;
   
  }

  .socials {
    display: flex;
  }
  
  #facebook {
    width: 80px;
    padding-right: 20px;
  }
  
  #twitter {
    width: 80px;
    padding-right: 20px;
  }
  
  #instagram {
    width: 60px;
  }

  .mySlides {
    display:none
  }
  .w3-tag, .fa {
    cursor:pointer
  }

  #admin-form {
    display: grid;
    grid-template-columns: auto;
    justify-content: center;
    text-align: center;
  }

  .w3-tag {
    height:15px;
    width:15px;
    padding:0;
    margin-top:6px
  }

  .w3-content {
    max-width:1100px;
    margin-top:80px;
    margin-bottom:80px;
    
  }

  #image {
    width: 100%;
  }
  #logo {
    width: 220px;
    
  }

  #header-bar {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto;
    
  }

  #mobile, #email, #socials {
    padding-top: 30px;
    text-decoration: none;
    
  }

  .mobile-icon {
    font-size: x-large;
    color: deepskyblue;
    margin-left: 40px;
    margin-top: 10px;
  }

  .email-icon {
    font-size: x-large;
    color: deepskyblue;
    margin-left: 80px;
    margin-top: 10px;
  }

  #mobile-box, #email-box {
    display: flex;
    flex-direction: column;
    
  }

 

  #email > a, #mobile > a {
    text-decoration: none;
  }

  #clients {
    display: grid;
    grid-template-columns: auto auto auto auto;
  }

   #plugdin {
    width: 200px;
    padding-top: 10px;
  }

  #bellgroup{
    width: 200px;
    
  }

  #ibn{
    width: 200px;
    padding-top: 40px;
  }

  #schuh {
    padding-top: 18px;
  }

  .line {
    margin-top: 10px;
    border-left: 2px solid gray;
    height: 80px;
  }

  #logo-mobile {
    width: 240px;
    
  }

  #line-mobile {
    border-top: 2px solid black;
  }

  #mobile-mob, #email-mob {
    padding-top: 10px;
    text-decoration: none;
    
  }

  .socials-mob {
  
    display: flex;
    justify-content: center;
   
  
  }

  #header-bar-mobile {
    display: grid;
    grid-template-columns: auto;
    justify-content: center;
  }

  #contact-icons {
    display: grid;
    grid-template-columns: auto auto;
  }

  .mobile-icon-mob {
    font-size: xx-large;
    color: deepskyblue;
    margin-left: 40px;
    margin-top: 10px;
  }

  .email-icon-mob {
    font-size: xx-large;
    color: deepskyblue;
    margin-left: 80px;
    margin-top: 10px;
  }

  .image-uploader {
    display: grid;
    grid-template-columns: auto;
    justify-content: center;
  }

  #image-upload-form {
    display: flex;
    flex-direction: column;
  }

  @media only screen and (max-width: 600px) {

   

  /* all good below */

  #clients {
    display: grid;
    grid-template-columns: auto auto;
    padding-left: 60px;
  }

   #plugdin {
    width: 120px;
    padding-top: 10px;
  }

  #bellgroup{
    width: 120px;
    padding-top: 10px;
  }

  #ibn{
    width: 120px;
    padding-top: 20px;
  }

  #schuh {
    width: 120px;
    padding-top: 18px;
  }

  .line {
    margin-top: 10px;
    border-bottom: 2px solid gray;
    height: 80px;
  }
    
 }

  

  